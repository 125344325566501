import React, { useEffect } from "react";
import "./modal.scss";

const YModal = (props) => {
    const { className, children } = props;
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "unset");
    }, []);
    return (
        <div {...props} className={"ymodal " + className}>
            <div>{children}</div>
        </div>
    );
};

export default YModal;

import React from "react";
import YTable from "../../components/table/YTable";
import { useGlobal } from "../../hooks/useGlobal";
import "./Backtest.scss";

const columns = [
    {
        name: "Col 1",
        onRender: (row) => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="left-side">{row.col1.left}</div>
                    <div className="right-side">{row.col1.right}</div>
                </div>
            );
        },
    },
    {
        name: "Col 2",
        onRender: (row) => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="left-side">{row.col2.left}</div>
                    <div className="right-side">{row.col2.right}</div>
                </div>
            );
        },
    },
    {
        name: "Col 3",
        onRender: (row) => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="left-side">{row.col3.left}</div>
                    <div className="right-side">{row.col3.right}</div>
                </div>
            );
        },
    },
];

const col1 = [
    {
        label: "History Quality",
        value: "winrate",
    },
    {
        label: "Bars",
        value: "bar",
    },
    {
        label: "Initial Deposit",
        value: "initialdeposit",
    },
    {
        label: "Total Net Profit",
        value: "netprofit",
    },
    {
        label: "Gross Profit",
        value: "grossprofit",
    },
    {
        label: "Gross Loss",
        value: "grossloss",
    },
    {
        label: "Total Trades",
        value: "totaltrades",
    },
    {
        label: "Total Deals",
        value: "totaldeals",
    },
    {
        label: "",
        value: "Largest",
    },
    {
        label: "Longest time go home",
        value: "longestTimeGoHome",
    },
    {
        label: "Max lot we have",
        value: "maxLot",
    },
];

const col2 = [
    {
        label: "",
        value: "",
    },
    {
        label: "Ticks",
        value: "tick",
    },
    {
        label: "Balanse Drawdown Maximal",
        value: "maxDrawDown",
    },
    {
        label: "Expected Payoff",
        value: "expectedPayoff",
    },
    {
        label: "Sharpe Ratio",
        value: "sharpeRatio",
    },
    {
        label: "",
        value: "",
    },
    {
        label: "Short Trade(won %)",
        value: "shorttrade",
    },
    {
        label: "Profit Trades(% of total)",
        value: "profittrades",
    },
    {
        label: "Largest profit trade",
        value: "largestprofittrade",
    },
    {
        label: "Avg profit trade",
        value: "avgprofittrade",
    },
    {
        label: "Max consecutive wins",
        value: "maxconsecutivewins",
    },
];

const col3 = [
    {
        label: "",
        value: "",
    },
    {
        label: "Symbol",
        value: "Symbol",
    },
    {
        label: "Equity Drawdown Maximal",
        value: "absoluteDrawDown",
    },
    {
        label: "Profit Factor",
        value: "profitFactor",
    },
    {
        label: "Recovery Factor",
        value: "recoveryFactor",
    },
    {
        label: "",
        value: "",
    },
    {
        label: "Long Trades (won %)",
        value: "longtrades",
    },
    {
        label: "Loss Trades (% of total)",
        value: "losstrades",
    },
    {
        label: "Largest loss trade",
        value: "largestlosstrade",
    },
    {
        label: "Avg loss trade",
        value: "avglosstrade",
    },
    {
        label: "Max consecutive losses($)",
        value: "maxconsecutivelosses",
    },
];

const Backtest = () => {
    const { state } = useGlobal();
    const { backtest } = state;
    const getBacktestArray = () => {
        let data = [];
        [...Array(11)]
            .map((_, i) => i)
            .forEach(function (key, index) {
                let row = {
                    col1: {
                        left: col1[index].label,
                        right: backtest[col1[index].value],
                    },
                    col2: {
                        left: col2[index].label,
                        right: backtest[col2[index].value],
                    },
                    col3: {
                        left: col3[index].label,
                        right: backtest[col3[index].value],
                    },
                };
                data.push(row);
            });
        return data;
    };
    return (
        <div>
            <YTable noHeader columns={columns} data={getBacktestArray()} />
            {/* {JSON.stringify(backtest)} */}
        </div>
    );
};

export default Backtest;

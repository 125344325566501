// import logo from './logo.svg';
import "./App.scss";
import YChart from "./components/chart/YChart";
import YTab from "./components/tab/YTab";
import { GlobalProvider } from "./hooks/useGlobal";
import Backtest from "./pages/backtest/Backtest";
import Inputs from "./pages/inputs/Inputs";
import Journal from "./pages/journal/Journal";
import Optimization from "./pages/optimization/Optimization";
import Result from "./pages/result/Result";
import Resultv2 from "./pages/result/Resultv2";
import Settings from "./pages/settings/Settings";

function App() {
    return (
        <GlobalProvider>
            <div className="App">
                <section className="tab-section">
                    <YTab activeTab={"Settings"}>
                        <div label="Settings">
                            <Settings />
                        </div>
                        <div label="Inputs">
                            <Inputs />
                        </div>
                        <div label="Result">
                            <Resultv2 />
                        </div>
                        <div label="Backtest">
                            <Backtest />
                        </div>
                        <div label="Optimization results">
                            <Optimization />
                        </div>
                        <div label="Journal">
                            <Journal />
                        </div>
                    </YTab>
                </section>
                <section className="chart-section">
                    <YChart />
                </section>
            </div>
        </GlobalProvider>
    );
}

export default App;

import React, { useEffect } from "react";
import { useState } from "react";
import YButton from "../../components/button/YButton";
import YList from "../../components/list/YList";
import YTable from "../../components/table/YTable";
// import YTable from "../../components/table/YTable";
import { useGlobal } from "../../hooks/useGlobal";
import { splitList2Page } from "../../utils/list";
import "./Result.scss";

const columns = [
    {
        name: "#",
        selector: (row) => row.id,
        sortable: true,
        // onRender: (row) => {
        //     return (
        //         <>
        //             <input type="checkbox" />
        //             <span>{row.variable}</span>
        //         </>
        //     );
        // },
    },
    {
        name: "Time",
        selector: (row) => row.time,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Type",
        selector: (row) => row.type,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Order",
        selector: (row) => row.order,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Size",
        selector: (row) => row.size,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Price",
        selector: (row) => row.price,
        sortable: true,
        // disabled: true,
    },
    {
        name: "S / L",
        selector: (row) => row.sl,
        sortable: true,
        // disabled: true,
    },
    {
        name: "T / P",
        selector: (row) => row.tp,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Profit",
        selector: (row) => row.profit,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Balances",
        selector: (row) => row.balance,
        sortable: true,
        // disabled: true,
    },
    {
        name: "Magic number",
        selector: (row) => row.magic,
        sortable: true,
        // disabled: true,
    },
];

const Resultv2 = () => {
    const { state, focus } = useGlobal();
    const { result } = state;
    const dataPage = splitList2Page({ list: result, pageSize: 10 });
    const [pageData, setPageData] = useState({
        pageNumber: 0,
        pageSize: 10,
    });

    useEffect(() => {
        // FOCUS = ID
        if (focus < 0 || focus >= result.length) return;
        const indexOfFocus = result.findIndex((item) => item.id === focus);
        // const totalPage = Math.floor(result.length / pageData.pageSize);
        const currentPage = Math.floor(indexOfFocus / pageData.pageSize);
        setPageData({
            ...pageData,
            pageNumber: currentPage,
        });

        // if (didMountRef.current && focus === index) {
        //     executeScroll();
        // }
        // didMountRef.current = true;
    }, [focus]);

    const handlePrevious = () => {
        setPageData({
            ...pageData,
            pageNumber: pageData.pageNumber - 1,
        });
    };

    const handleNext = () => {
        setPageData({
            ...pageData,
            pageNumber: pageData.pageNumber + 1,
        });
    };

    return (
        <div className="Result">
            {Array.isArray(dataPage[pageData.pageNumber]) && (
                <YTable
                    columns={columns}
                    data={dataPage[pageData.pageNumber]}
                    focus={focus}
                />
            )}
            {Array.isArray(dataPage) && dataPage.length > 0 && (
                <div className="pagination">
                    <YButton
                        title={"Previous"}
                        onClick={handlePrevious}
                        disabled={pageData.pageNumber === 0}
                    />
                    <span className="current-page">
                        {pageData.pageNumber + 1}
                    </span>
                    <YButton
                        title={"Next"}
                        onClick={handleNext}
                        disabled={pageData.pageNumber === dataPage.length}
                    />
                </div>
            )}
        </div>
    );
};

export default Resultv2;

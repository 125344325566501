import { useEffect } from "react";

const useOutsideAlerter = (props) => {
    const { ref, handleClickOutside: handleClickOutsideProps } = props;

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClickOutsideProps();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, handleClickOutsideProps]);

    return {
        ref,
    };
};

export default useOutsideAlerter;

import React from "react";
import YTable from "../../components/table/YTable";
import "./Optimization.scss";

const columns = [
    {
        name: "#",
        selector: (row) => row.variable,
        sortable: true,
        // onRender: (row) => {
        //     return (
        //         <>
        //             <input type="checkbox" />
        //             <span>{row.variable}</span>
        //         </>
        //     );
        // },
    },
    {
        name: "Time",
        selector: (row) => row.value,
        sortable: true,
        disabled: true,
    },
    {
        name: "Type",
        selector: (row) => row.start,
        sortable: true,
        disabled: true,
    },
    {
        name: "Order",
        selector: (row) => row.step,
        sortable: true,
        disabled: true,
    },
    {
        name: "Size",
        selector: (row) => row.stop,
        sortable: true,
        disabled: true,
    },
    {
        name: "Price",
        selector: (row) => row.steps,
        sortable: true,
        disabled: true,
    },
    {
        name: "S / L",
        selector: (row) => row.steps,
        sortable: true,
        disabled: true,
    },
    {
        name: "T / P",
        selector: (row) => row.steps,
        sortable: true,
        disabled: true,
    },
    {
        name: "Profit",
        selector: (row) => row.steps,
        sortable: true,
        disabled: true,
    },
];

const data = [
    {
        id: 1,
        variable: "Variable",
        value: "Value",
        start: "Start",
        step: "Step",
        stop: "Stop",
        steps: "Steps",
    },
    {
        id: 2,
        variable: "Variable",
        value: "Value",
        start: "Start",
        step: "Step",
        stop: "Stop",
        steps: "Steps",
    },
    {
        id: 3,
        variable: "Variable",
        value: "Value",
        start: "Start",
        step: "Step",
        stop: "Stop",
        steps: "Steps",
    },
    {
        id: 4,
        variable: "Variable",
        value: "Value",
        start: "Start",
        step: "Step",
        stop: "Stop",
        steps: "Steps",
    },
    {
        id: 5,
        variable: "Variable",
        value: "Value",
        start: "Start",
        step: "Step",
        stop: "Stop",
        steps: "Steps",
    },
    {
        id: 6,
        variable: "Variable",
        value: "Value",
        start: "Start",
        step: "Step",
        stop: "Stop",
        steps: "Steps",
        disabled: true,
    },
];

const Optimization = () => {
    return (
        <div>
            <YTable columns={columns} data={data} />
        </div>
    );
};

export default Optimization;

import React from "react";

const Tab = (props) => {
    const { label, onClick: onClickProps, activeTab } = props;
    const onClick = () => {
        onClickProps(label);
    };
    return (
        <li
            className={
                "tab-list-item" +
                (activeTab === label ? " tab-list-active" : "")
            }
            onClick={onClick}
        >
            {label}
        </li>
    );
};

export default Tab;

import React, { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import "./YDropdown.scss";

const YDropdown = (props) => {
    const { options = [], placeholder, id, selectedValue, onChange } = props;
    const wrapperRef = useRef(null);
    const handleClickOutside = () => {
        setIsActive(false);
    };
    useOutsideAlerter({
        ref: wrapperRef,
        handleClickOutside,
    });
    const [isActive, setIsActive] = useState(false);
    const getValueOrDefault = (options, value) => {
        if (!Array.isArray(options) || !options?.length)
            return {
                value: 0,
                label: 0,
                id: 0,
            };
        let selectedOption = options.find((opt) => opt.value === value);
        if (!selectedOption) return options[0];
        return selectedOption;
    };
    const [selectedOption, setSelectedOption] = useState(
        getValueOrDefault(options, selectedValue)
    );

    useEffect(() => {
        setSelectedOption(getValueOrDefault(options, selectedValue));
    }, [selectedValue, options]);

    const onExpand = (e) => {
        e.stopPropagation();
        setIsActive((x) => !x);
    };

    const onSelect = (option) => {
        setSelectedOption(option);
        onChange(option);
        setIsActive(false);
    };

    const displayValue = selectedOption?.label || placeholder;

    return (
        <div ref={wrapperRef} className="select" id={id}>
            <select className="select-hidden">
                {displayValue && <option value="hide">{displayValue}</option>}
                {options.map((option) => {
                    const { value, label, id } = option;
                    return (
                        <option key={value} id={id || value} value={value}>
                            {label}
                        </option>
                    );
                })}
            </select>
            {displayValue && (
                <div
                    className={"select-styled " + (isActive ? "active" : "")}
                    onClick={onExpand}
                >
                    {displayValue}
                </div>
            )}
            <ul
                className="select-options"
                style={{
                    display: isActive ? "block" : "none",
                }}
                // onClick={onExpand}
            >
                {displayValue && <li rel="hide">{displayValue}</li>}
                {options.map((option) => {
                    const { value, label, id } = option;
                    return (
                        <li
                            id={id || value}
                            key={value}
                            rel={value}
                            onClick={(e) => {
                                e.stopPropagation();
                                onSelect(option);
                            }}
                        >
                            {label}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default YDropdown;

import React from "react";
import YTable from "../../components/table/YTable";
import { useGlobal } from "../../hooks/useGlobal";
import "./Journal.scss";

const columns = [
    {
        name: "Time",
        selector: (row) => row.time,
        sortable: true,
        className: "time-col",
        // disabled: true,
    },
    {
        name: "Message",
        selector: (row) => row.content,
        sortable: true,
        // disabled: true,
    },
];

const Journal = () => {
    const { state } = useGlobal();
    const { journal } = state;
    // const {second} =
    return (
        <div className="Journal">
            <YTable columns={columns} data={journal} />
        </div>
    );
};

export default Journal;

import React, { useMemo, useRef, useState } from "react";
import "./YChart.scss";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line, getElementAtEvent } from "react-chartjs-2";
import YModal from "../modal";
import YButton from "../button/YButton";
import { useGlobal } from "../../hooks/useGlobal";
import YDropdown from "../dropdown/YDropdown";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    elements: {
        point: {
            radius: 1.2,
        },
        line: {
            borderWidth: 0.75,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: "Chart.js Line Chart",
        },
    },
    // scales: {
    //     y: {
    //         type: "linear",
    //         display: true,
    //         position: "left",
    //     },
    //     y1: {
    //         type: "linear",
    //         display: true,
    //         position: "right",
    //         grid: {
    //             drawOnChartArea: false,
    //         },
    //     },
    // },
};

// export const data = ;

const YChart = () => {
    const { state, updateChart, onClickFocus } = useGlobal();
    const { backtest } = state;
    const { chart: chartData } = backtest;
    const chartRef = useRef(null);

    const [numberItem, setNumberItem] = useState(0);

    const onClick = (event) => {
        const { current: chart } = chartRef;
        if (!chart) {
            return;
        }
        printElementAtEvent(getElementAtEvent(chart, event));
    };

    const printElementAtEvent = (element) => {
        if (!element.length) return;

        const { datasetIndex, index } = element[0];
        // onClickFocus(index);
        onClickFocus(data.labels[index]);
        console.log(
            data.labels[index],
            data.datasets[datasetIndex].data[index]
        );
    };
    const [fullScreen, setFullScreen] = useState(false);
    const handleFullScreen = () => {
        setFullScreen(true);
    };

    const handleExit = () => {
        setFullScreen(false);
    };

    const [chartPage, setChartPage] = useState({
        pageNumber: 0,
        pageCount: "All",
    });

    const getLabels = (chartData) => {
        if (chartData?.id) {
            if (
                chartPage.pageCount == "All" ||
                chartPage.pageCount > chartData.id.length
            )
                return chartData.id;
            return chartData.id.slice(
                chartPage.pageNumber * chartPage.pageCount,
                chartPage.pageCount * (chartPage.pageNumber + 1)
            );
        }
        return [];
    };

    const getBalances = (chartData) => {
        if (chartData?.balances) {
            if (
                chartPage.pageCount === "All" ||
                chartPage.pageCount > chartData.balances.length
            )
                return chartData.balances;
            return chartData.balances.slice(
                chartPage.pageNumber * chartPage.pageCount,
                chartPage.pageCount * (chartPage.pageNumber + 1)
            );
        }
        return [];
    };

    const data = {
        labels: getLabels(chartData),
        datasets: [
            {
                label: "Balances",
                type: "line",
                data: getBalances(chartData),
                borderColor: "#0202b0",
                backgroundColor: "#0202b0",
                options: {
                    legend: {
                        display: false, //This will do the task
                    },
                },
                // hidden: true
            },
        ],
    };

    const memoChart = useMemo(
        () => (
            <Line
                redraw={true}
                updateMode="active"
                ref={chartRef}
                options={options}
                data={data}
                onClick={onClick}
                key={fullScreen}
            />
        ),
        [updateChart, chartPage.pageNumber, chartPage.pageCount]
    );

    const handleNextChart = () => {
        setChartPage({
            ...chartPage,
            pageNumber: Number(chartPage.pageNumber + 1),
        });
    };

    const handlePreviousChart = () => {
        setChartPage({
            ...chartPage,
            pageNumber: Number(chartPage.pageNumber - 1),
        });
    };

    const handleChangeNumberChart = (option) => {
        setChartPage({
            ...chartPage,
            pageNumber: 0,
            pageCount: option.value,
        });
    };

    return (
        <div className="YChart">
            <div className="performancevisualization-container__footer">
                <YButton title="Full screen" onClick={handleFullScreen} />
                <div className="pagination-chart">
                    <span>Number of points: </span>
                    <YDropdown
                        id="number_point"
                        options={[
                            {
                                label: "All",
                                value: "All",
                            },
                            {
                                label: 100,
                                value: 100,
                            },
                            {
                                label: 200,
                                value: 200,
                            },
                            {
                                label: 500,
                                value: 500,
                            },
                            {
                                label: 1000,
                                value: 1000,
                            },
                            {
                                label: 10000,
                                value: 10000,
                            },
                        ]}
                        selectedValue={chartPage.pageCount || "All"}
                        onChange={handleChangeNumberChart}
                    />
                    <YButton
                        disabled={
                            chartPage.pageCount === "All" ||
                            chartPage.pageNumber === 0
                        }
                        title="Previous"
                        onClick={handlePreviousChart}
                    />
                    <div className="number-point">
                        {chartPage.pageNumber + 1}
                    </div>
                    <YButton
                        disabled={
                            chartPage.pageCount === "All" ||
                            chartPage.pageNumber ===
                                Math.floor(
                                    chartData.balances.length /
                                        chartPage.pageCount
                                )
                        }
                        title="Next"
                        onClick={handleNextChart}
                    />
                </div>
            </div>
            {!fullScreen && memoChart}
            {fullScreen && (
                <YModal>
                    {memoChart}
                    <YButton
                        className="exit-button"
                        title="Exit"
                        onClick={handleExit}
                    />
                </YModal>
            )}
        </div>
    );
};

export default YChart;

import React from "react";
import { useState } from "react";
import "./YTable.scss";

const YTable = (props) => {
    const {
        columns,
        data,
        noHeader = false,
        focus,
        canExpand,
        onRenderHeader,
    } = props;

    const [expand, setExpand] = useState(true);

    const handleClickHeader = () => {
        if (!canExpand) return;
        setExpand((x) => !x);
    };

    const renderColumnHeader = () => {
        const renderIcon = canExpand ? expand ? "- " : "+ " : <></>;
        if (onRenderHeader) {
            return (
                <tr
                    className={canExpand ? "canExpand" : ""}
                    onClick={handleClickHeader}
                >
                    {onRenderHeader(renderIcon)}
                </tr>
            );
        }
        return (
            <tr
                className={canExpand ? "canExpand" : ""}
                onClick={handleClickHeader}
            >
                {columns.map((column, index) => {
                    const { name } = column;
                    return (
                        <th key={index}>
                            {renderIcon}
                            {name}
                        </th>
                    );
                })}
            </tr>
        );
    };
    const renderColumnRow = () => {
        return (
            <>
                {data.map((row, index) => {
                    return (
                        <tr key={index}>
                            {columns.map((column, _index) => {
                                const {
                                    selector,
                                    name,
                                    disabled,
                                    onRender,
                                    className,
                                    type,
                                } = column;
                                let classname = disabled ? "disabled" : "";
                                if (className) {
                                    classname += " " + className;
                                }
                                if (type === "input") {
                                    classname += " td-input";
                                }
                                if (row?.id && focus === row.id) {
                                    classname += " focus";
                                }
                                if (onRender) {
                                    return (
                                        <td className={classname} key={_index}>
                                            {onRender(row)}
                                        </td>
                                    );
                                }
                                return (
                                    <td className={classname} key={_index}>
                                        {selector ? selector(row) : row[name]}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    return (
        <table className="YTable">
            {!noHeader && <thead>{renderColumnHeader()}</thead>}
            <tbody className={expand ? "expand" : "collapse"}>
                {renderColumnRow()}
            </tbody>
        </table>
    );
};

export default YTable;

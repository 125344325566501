import React from "react";
import YTable from "../../components/table/YTable";
import { CHANGE } from "../../contants";
import { useGlobal } from "../../hooks/useGlobal";
import { isNumeric } from "../../utils/math";
import "./Inputs.scss";

const columns = ({ onChangRow, optimization }) => [
    {
        name: "Variable",
        selector: (row) => row.name,
        sortable: true,
        onRender: (row) => {
            return (
                <>
                    <input
                        type="checkbox"
                        checked={!!row.optimize}
                        disabled={optimization === "Disabled"}
                        onChange={() =>
                            onChangRow({
                                key: "optimize",
                                value: !row.optimize,
                                name: row.name,
                            })
                        }
                    />
                    <span>{row.name}</span>
                </>
            );
        },
    },
    {
        name: "Value",
        selector: (row) => row.value,
        sortable: true,
        // disabled: row.optimize,
        type: "input",
        onRender: (row) => {
            if (isNumeric(row.value)) {
                return (
                    <input
                        type="number"
                        value={row.value}
                        disabled={row.optimize}
                        onChange={(e) =>
                            onChangRow({
                                key: "value",
                                value: Number(e.target.value),
                                name: row.name,
                            })
                        }
                    />
                );
            }
            return (
                <input
                    type="text"
                    value={row.value}
                    onChange={(e) =>
                        onChangRow({
                            key: "value",
                            value: e.target.value,
                            name: row.name,
                        })
                    }
                />
            );
        },
    },
    {
        name: "Start",
        selector: (row) => row.start,
        sortable: true,
        type: "input",
        onRender: (row) => {
            let isDisabled = !isNumeric(row.value);
            // if (isDisabled || !row.optimize) return <>{row.start}</>;
            return (
                <>
                    <input
                        type="number"
                        value={row.start}
                        disabled={!row.optimize || isDisabled}
                        onChange={(e) =>
                            onChangRow({
                                key: "start",
                                value: Number(e.target.value),
                                name: row.name,
                            })
                        }
                    />
                </>
            );
        },
    },
    {
        name: "Step",
        selector: (row) => row.step,
        sortable: true,
        type: "input",
        onRender: (row) => {
            let isDisabled = !isNumeric(row.value);
            // if (isDisabled || !row.optimize) return <>{row.step}</>;
            return (
                <>
                    <input
                        disabled={!row.optimize || isDisabled}
                        type="number"
                        value={row.step}
                        onChange={(e) =>
                            onChangRow({
                                key: "step",
                                value: Number(e.target.value),
                                name: row.name,
                            })
                        }
                    />
                </>
            );
        },
    },
    {
        name: "Stop",
        selector: (row) => row.stop,
        sortable: true,
        type: "input",
        onRender: (row) => {
            let isDisabled = !isNumeric(row.value);
            // if (isDisabled || !row.optimize) return <>{row.stop}</>;
            return (
                <>
                    <input
                        type="number"
                        value={row.stop}
                        disabled={!row.optimize || isDisabled}
                        onChange={(e) =>
                            onChangRow({
                                key: "stop",
                                value: Number(e.target.value),
                                name: row.name,
                            })
                        }
                    />
                </>
            );
        },
    },
    {
        name: "Steps",
        selector: (row) => row.steps,
        sortable: true,
        disabled: true,
        onRender: (row) => {
            let isDisabled = !isNumeric(row.value);
            if (isDisabled) return <></>;
            const { start, stop, step } = row;
            return (stop - start) / step + 1;
        },
    },
];

const Inputs = () => {
    const { state, dispatch } = useGlobal();
    const { postRun } = state;
    const { inputs, optimization } = postRun;
    const onChangRow = (data) => {
        console.log("data", data);
        dispatch({
            type: CHANGE.POSTRUN.INPUTS,
            payload: {
                data: {
                    ...data,
                },
            },
        });
    };
    return (
        <div>
            <YTable
                columns={columns({
                    onChangRow,
                    optimization,
                })}
                data={inputs}
            />
        </div>
    );
};

export default Inputs;

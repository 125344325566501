import React from "react";
import "./YButton.scss";

const YButton = (props) => {
    const { children, className, title } = props;
    return (
        <button
            {...props}
            className={"YButton " + (className ? className : "")}
        >
            {title || children}
        </button>
    );
};

export default YButton;

import React, { useState } from "react";
import YButton from "../../components/button/YButton";
import YDropdown from "../../components/dropdown/YDropdown";
import YModal from "../../components/modal";
import YTable from "../../components/table/YTable";
import YTimePicker from "../../components/timepicker/YTimePicker";
import { CHANGE } from "../../contants";
import { useGlobal } from "../../hooks/useGlobal";
import IconSync from "../../assets/sync.svg";
import "./Settings.scss";
import { isNumeric } from "../../utils/math";
// import moment from "moment";

const Settings = () => {
    const {
        state,
        dispatch,
        getInputs,
        getSyncSettings,
        getOpenIde,
        getProperties,
    } = useGlobal();
    const { settings, postRun } = state;
    const { experts = [], symbol = [] } = settings;
    const {
        expert,
        symbol: selectedSybol,
        timeframe,
        delay,
        modeling,
        deposit,
        leverage,
        optimization,
        date,
        properties,
    } = postRun;

    const handleChangeExpert = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.EXPERT,
            payload: {
                data: option?.value,
            },
        });
        getInputs({
            expert: option?.value,
        });
    };
    const handleChangeSymbol = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.SYMBOL,
            payload: {
                data: option?.value,
            },
        });
        getProperties({
            symbol: option?.value,
        });
    };

    const handleChangeTimeFrom = (e) => {
        dispatch({
            type: CHANGE.POSTRUN.DATE,
            payload: {
                data: {
                    start: e.target.value,
                    //  new Date(e.target.value).getTime() / 1000,
                },
            },
        });
    };
    const handleChangeTimeTo = (e) => {
        dispatch({
            type: CHANGE.POSTRUN.DATE,
            payload: {
                data: {
                    end: e.target.value,
                    //  new Date(e.target.value).getTime() / 1000,
                },
            },
        });
    };

    const handleChangeTimeFrame = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.TIMEFRAME,
            payload: {
                data: option?.value,
            },
        });
    };

    const handleChangeDelay = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.DELAY,
            payload: {
                data: option?.value,
            },
        });
    };

    const handleChangeModeling = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.MODELING,
            payload: {
                data: option?.value,
            },
        });
    };

    const handleChangeDepositValue = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.DEPOSIT,
            payload: {
                data: {
                    value: option?.value,
                },
            },
        });
    };

    const handleChangeDepositType = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.DEPOSIT,
            payload: {
                data: {
                    type: option?.value,
                },
            },
        });
    };

    const handleChangeLeverage = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.LEVERAGE,
            payload: {
                data: option?.value,
            },
        });
    };

    const handleChangeOptimization = (option) => {
        dispatch({
            type: CHANGE.POSTRUN.OPTIMIZATION,
            payload: {
                data: option?.value,
            },
        });
    };

    const [isOpenTestedSymbolModal, setIsOpenTestedSymbolModal] =
        useState(false);

    const handleClickTestedSymbol = () => {
        console.log("");
    };

    const handleClickModal = () => {
        setIsOpenTestedSymbolModal(false);
    };

    const handleClickSync = () => {
        getSyncSettings();
    };

    const convertDataTable = (data) => {
        let items = [];
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === "object" &&
                    data[key] !== null &&
                    !Array.isArray(data[key])
                ) {
                    items[data[key].order] = {
                        key: data[key].key,
                        originKey: key,
                        value:
                            typeof data[key].value === "object" &&
                            data[key].value !== null
                                ? JSON.stringify(data[key].value)
                                : data[key].value,
                    };
                } else {
                    items[data[key].order] = {
                        key: key,
                        originKey: key,
                        value:
                            typeof data[key] === "object" && data[key] !== null
                                ? JSON.stringify(data[key])
                                : data[key],
                    };
                }
            }
        }
        return items;
    };

    const columnsDataTable = ({ onChangRow, key }) => {
        return [
            {
                className: "symbol-col-1",
                name: "Swaps",
                selector: (row) => row.key,
            },
            {
                name: "",
                className: "symbol-col-2",
                selector: (row) => row.value,
                type: "input",
                onRender: (row) => {
                    if (isNumeric(row.value)) {
                        return (
                            <>
                                <input
                                    type="number"
                                    value={row.value}
                                    onChange={(e) =>
                                        onChangRow({
                                            key: key,
                                            value: Number(e.target.value),
                                            subKey: row.originKey,
                                        })
                                    }
                                />
                            </>
                        );
                    }
                    return (
                        <input
                            type="text"
                            value={row.value}
                            onChange={(e) =>
                                onChangRow({
                                    key: key,
                                    value: e.target.value,
                                    subKey: row.originKey,
                                })
                            }
                        />
                    );
                },
            },
        ];
    };

    const onChangRow = (data) => {
        console.log("data", data);
        dispatch({
            type: CHANGE.POSTRUN.PROPERTIES,
            payload: {
                data: {
                    ...data,
                },
            },
        });
    };

    const convertDataMultiTable = (properties) => {
        if (!properties) return <></>;
        let items = [];
        for (var key in properties) {
            if (properties.hasOwnProperty(key)) {
                const onRenderHeader = (key) => {
                    return (renderIcon) => {
                        return (
                            <th colSpan="2">
                                {renderIcon} {key}
                            </th>
                        );
                    };
                };
                items[properties[key].order] = (
                    <YTable
                        key={key}
                        columns={columnsDataTable({
                            onChangRow,
                            key,
                        })}
                        onRenderHeader={onRenderHeader(key)}
                        data={convertDataTable(properties[key])}
                        canExpand
                    />
                );
            }
        }
        return items;
    };

    return (
        <div className="Settings">
            <div className="export-container row-input">
                <div span={3} className="export-container__label label">
                    Expert:
                </div>
                <div span={9} className="export-container__input">
                    <YDropdown
                        id="export"
                        options={experts.map((expert) => {
                            return {
                                label: expert,
                                value: expert,
                            };
                        })}
                        selectedValue={expert || experts?.[0]}
                        onChange={handleChangeExpert}
                    />
                    <YButton
                        type="button"
                        className="btn-ide"
                        onClick={() => {
                            getOpenIde();
                        }}
                    >
                        IDE
                    </YButton>
                    <YButton
                        type="button"
                        className="btn-ide"
                        onClick={handleClickTestedSymbol}
                    >
                        SE
                    </YButton>
                    <YButton
                        type="button"
                        className="btn-ide"
                        onClick={handleClickSync}
                    >
                        <img width={22} height={22} src={IconSync} alt="" />
                    </YButton>
                </div>
            </div>
            <div className="symbol-container row-input">
                <div span={3} className="symbol-container__label label">
                    Symbol:
                </div>
                <div span={9} className="symbol-container__input">
                    <YDropdown
                        id="symbol"
                        options={symbol.map((sym) => {
                            return {
                                label: sym,
                                value: sym,
                            };
                        })}
                        selectedValue={selectedSybol || symbol?.[0]}
                        onChange={handleChangeSymbol}
                    />
                    <YDropdown
                        id="symbol2"
                        options={[
                            {
                                label: "M1",
                                value: "M1",
                            },
                            {
                                label: "M2",
                                value: "M2",
                            },
                            {
                                label: "M3",
                                value: "M3",
                            },
                            {
                                label: "M4",
                                value: "M4",
                            },
                            {
                                label: "M5",
                                value: "M5",
                            },
                            {
                                label: "M6",
                                value: "M6",
                            },
                            {
                                label: "M10",
                                value: "M10",
                            },
                            {
                                label: "M12",
                                value: "M12",
                            },
                            {
                                label: "M15",
                                value: "M15",
                            },
                            {
                                label: "M20",
                                value: "M20",
                            },
                            {
                                label: "M30",
                                value: "M30",
                            },
                            {
                                label: "H1",
                                value: "H1",
                            },
                            {
                                label: "H2",
                                value: "H2",
                            },
                            {
                                label: "H3",
                                value: "H3",
                            },
                            {
                                label: "H4",
                                value: "H4",
                            },
                            {
                                label: "H6",
                                value: "H6",
                            },
                            {
                                label: "H8",
                                value: "H8",
                            },
                            {
                                label: "H12",
                                value: "H12",
                            },
                            {
                                label: "Daily",
                                value: "Daily",
                            },
                            {
                                label: "Weekly",
                                value: "Weekly",
                            },
                            {
                                label: "Monthly",
                                value: "Monthly",
                            },
                        ]}
                        selectedValue={timeframe || "M1"}
                        onChange={handleChangeTimeFrame}
                    />
                    {isOpenTestedSymbolModal && (
                        <YModal
                            className="tested-symbol-modal"
                            onClick={handleClickModal}
                        >
                            <div
                                className="modal-content"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    return;
                                }}
                            >
                                <div>
                                    <div className="modal-header">
                                        <div className="header-text">
                                            Tested symbol
                                        </div>
                                        <div className="btn-group">
                                            <YButton
                                                onClick={() =>
                                                    setIsOpenTestedSymbolModal(
                                                        false
                                                    )
                                                }
                                            >
                                                Close
                                            </YButton>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        {convertDataMultiTable(properties)}
                                        <div className="btn-symbol-group">
                                            <div className="left-side">
                                                <YButton
                                                    className="btn-symbol-action"
                                                    onClick={() => {}}
                                                >
                                                    <span>Default</span>
                                                </YButton>
                                                <YButton
                                                    className="btn-symbol-action"
                                                    onClick={() => {}}
                                                >
                                                    <span>Import</span>
                                                </YButton>
                                                <YButton
                                                    className="btn-symbol-action"
                                                    onClick={() => {}}
                                                >
                                                    <span>Export</span>
                                                </YButton>
                                            </div>
                                            <div className="right-side">
                                                <YButton
                                                    className="btn-symbol-action"
                                                    onClick={() => {}}
                                                >
                                                    <span>OK</span>
                                                </YButton>
                                                <YButton
                                                    className="btn-symbol-action"
                                                    onClick={() => {}}
                                                >
                                                    <span>Cancel</span>
                                                </YButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </YModal>
                    )}
                    <YButton
                        className="btn-dollar"
                        onClick={() => setIsOpenTestedSymbolModal(true)}
                    >
                        <span>$</span>
                    </YButton>
                </div>
            </div>
            <div className="date-container row-input">
                <div span={3} className="date-container__label label">
                    Date:
                </div>
                <div span={9} className="date-container__input">
                    <div className="date-star-group">
                        <YDropdown
                            id="date"
                            options={[
                                {
                                    label: "Entire history",
                                    value: "Entire history",
                                },
                                // {
                                //     label: "Last month",
                                //     value: "Last month",
                                // },
                                // {
                                //     label: "Last year",
                                //     value: "Last year",
                                // },
                                // {
                                //     label: "Custom period",
                                //     value: "Custom period",
                                // },
                            ]}
                            // TODO: young
                            selectedValue={"Entire history"}
                        />
                        <YTimePicker
                            type="datetime-local"
                            id="start_date"
                            name="start_date"
                            // value={moment(date[0]).format("YYYY-MM-DDTkk:mm")}
                            value={date[0]}
                            onChange={handleChangeTimeFrom}
                        />
                    </div>
                    <YTimePicker
                        type="datetime-local"
                        id="end_date"
                        name="end_date"
                        // value={moment(date[1]).format("YYYY-MM-DDTkk:mm")}
                        value={date[1]}
                        onChange={handleChangeTimeTo}
                    />
                </div>
            </div>
            <div className="second-group">
                <div className="delay-container row-input">
                    <div span={3} className="delay-container__label label">
                        Delays:
                    </div>
                    <div span={9} className="delay-container__input">
                        <YDropdown
                            id="delay"
                            options={[
                                {
                                    label: "Zero latency, ideal execution",
                                    value: "Zero latency, ideal execution",
                                },
                                // {
                                //     label: "1 ms",
                                //     value: "1 ms",
                                // },
                                // {
                                //     label: "5 ms",
                                //     value: "5 ms",
                                // },
                                // {
                                //     label: "10 ms",
                                //     value: "10 ms",
                                // },
                                // {
                                //     label: "20 ms",
                                //     value: "20 ms",
                                // },
                                // {
                                //     label: "50 ms",
                                //     value: "50 ms",
                                // },
                                // {
                                //     label: "100 ms",
                                //     value: "100 ms",
                                // },
                                // {
                                //     label: "500 ms",
                                //     value: "500 ms",
                                // },
                                // {
                                //     label: "1000 ms",
                                //     value: "1000 ms",
                                // },
                                // {
                                //     label: "Random delay",
                                //     value: "Random delay",
                                // },
                                // {
                                //     label: "Custom delay",
                                //     value: "Custom delay",
                                // },
                            ]}
                            selectedValue={
                                delay || "Zero latency, ideal execution"
                            }
                            onChange={handleChangeDelay}
                        />
                    </div>
                </div>
                <div className="modelling-container row-input">
                    <div span={3} className="modelling-container__label label">
                        Modelling:
                    </div>
                    <div span={9} className="modelling-container__input">
                        <YDropdown
                            id="modelling"
                            options={[
                                // {
                                //     label: "Every tick",
                                //     value: "Every tick",
                                // },
                                {
                                    label: "Every tick based on real ticks",
                                    value: "every tick based on real tick",
                                },
                                // {
                                //     label: "1 minute OHLC",
                                //     value: "1 minute OHLC",
                                // },
                                // {
                                //     label: "Open prices only",
                                //     value: "Open prices only",
                                // },
                                // {
                                //     label: "Math calculations",
                                //     value: "Math calculations",
                                // },
                            ]}
                            selectedValue={modeling || "Every tick"}
                            onChange={handleChangeModeling}
                        />
                    </div>
                </div>
                <div className="deposit-container row-input">
                    <div span={3} className="deposit-container__label label">
                        Deposit:
                    </div>
                    <div span={9} className="deposit-container__input">
                        <div className="input-container">
                            <div className="left-part">
                                <YDropdown
                                    id="deposit"
                                    options={[
                                        {
                                            label: "3000",
                                            value: 3000,
                                        },
                                        {
                                            label: "5000",
                                            value: 5000,
                                        },
                                        {
                                            label: "10000",
                                            value: 10000,
                                        },
                                        {
                                            label: "25000",
                                            value: 25000,
                                        },
                                        {
                                            label: "50000",
                                            value: 50000,
                                        },
                                        {
                                            label: "100000",
                                            value: 100000,
                                        },
                                    ]}
                                    selectedValue={deposit.value || "1"}
                                    onChange={handleChangeDepositValue}
                                />
                                <YDropdown
                                    id="unit"
                                    options={[
                                        {
                                            label: "USD",
                                            value: "USD",
                                        },
                                        // {
                                        //     label: "EUR",
                                        //     value: "EUR",
                                        // },
                                        // {
                                        //     label: "GBP",
                                        //     value: "GBP",
                                        // },
                                        // {
                                        //     label: "CHF",
                                        //     value: "CHF",
                                        // },
                                        // {
                                        //     label: "RUB",
                                        //     value: "RUB",
                                        // },
                                    ]}
                                    selectedValue={deposit.type || "USD"}
                                    onChange={handleChangeDepositType}
                                />
                            </div>
                            <YDropdown
                                id="leverage"
                                options={[
                                    {
                                        label: "1:100",
                                        value: "1:100",
                                    },
                                    {
                                        label: "1:200",
                                        value: "1:200",
                                    },
                                    {
                                        label: "1:500",
                                        value: "1:500",
                                    },
                                    {
                                        label: "1:50",
                                        value: "1:50",
                                    },
                                    {
                                        label: "1:33",
                                        value: "1:33",
                                    },
                                    {
                                        label: "1:25",
                                        value: "1:25",
                                    },
                                    {
                                        label: "1:15",
                                        value: "1:15",
                                    },
                                    {
                                        label: "1:10",
                                        value: "1:10",
                                    },
                                    {
                                        label: "1:5",
                                        value: "1:5",
                                    },
                                    {
                                        label: "1:3",
                                        value: "1:3",
                                    },
                                    {
                                        label: "1:1",
                                        value: "1:1",
                                    },
                                ]}
                                selectedValue={leverage || "1:1"}
                                onChange={handleChangeLeverage}
                            />
                        </div>
                        <div className="leverage">leverage</div>
                    </div>
                </div>
                <div className="unit-container row-input">
                    <div span={9} className="unit-container__input"></div>
                </div>
                <div className="optimization-container row-input">
                    <div
                        span={3}
                        className="optimization-container__label label"
                    >
                        Optimization:
                    </div>
                    <div span={9} className="optimization-container__input">
                        <YDropdown
                            id="optimization"
                            options={[
                                {
                                    label: "Disabled",
                                    value: "Disabled",
                                },
                                // {
                                //     label: "Slow completed algorithm",
                                //     value: "Slow completed algorithm",
                                // },

                                // {
                                //     label: "Fast genetic algorithm",
                                //     value: "Fast genetic algorithm",
                                // },

                                // {
                                //     label: "All symbols selected  in MarketWatch",
                                //     value: "All symbols selected  in MarketWatch",
                                // },
                            ]}
                            selectedValue={optimization || "Disabled"}
                            onChange={handleChangeOptimization}
                        />
                        {/* TODO: tính năng chưa hoạt đống */}
                        {/* <div className="checkbox-container">
                            <input type="checkbox" />
                            <span>
                                visual mode with the display of charts,
                                indicators and trades
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;

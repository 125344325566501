import React, { useContext, useEffect, useReducer, useState } from "react";
import { globalReducer, initialState } from "./reducer";
import axios from "axios";
import { CHANGE } from "../contants";

const Progress = {
    None: -1,
    Init: 0,
    LoadData: 1,
    InitStrategy: 2,
    PrePlaceOrder: 3,
    PlaceOrder: 4,
    AnalyzeResult: 5,
    SaveResult: 6,
    Done: 7,
};

const GlobalContext = React.createContext();
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, initialState);

    const [processing, setProcessing] = useState(Progress.None);
    const [currentId, setCurrentId] = useState(0);
    const [updateChart, setUpdateChart] = useState(false);
    const [focus, setFocus] = useState();
    const [activeTab, setActiveTab] = useState("Settings");

    const getSettings = async () => {
        try {
            const { data: settings } = await axios({
                method: "get",
                url: "/getSettings",
            });
            if (settings?.message) {
                const { time, content } = settings?.message;
                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (!!settings?.data) {
                dispatch({
                    type: CHANGE.SETTINGS.EXPERT,
                    payload: {
                        data: settings?.data?.experts,
                    },
                });
                // let selectedOption = settings?.data?.experts.find(
                //     (opt) => opt === state.postRun?.expert
                // );
                getInputs({
                    expert: settings?.data?.experts[0],
                });
                getProperties({
                    symbol: settings?.data?.symbol[0],
                });
                dispatch({
                    type: CHANGE.SETTINGS.SYMBOL,
                    payload: {
                        data: settings?.data?.symbol,
                    },
                });
            }
        } catch (error) {
            // dispatch({
            //     type: CHANGE.JOURNAL,
            //     payload: {
            //         data: settings?.data?.symbol,
            //     },
            // });
        }
    };

    const getProcess = async ({ id = 1 }) => {
        try {
            const { data } = await axios({
                method: "get",
                url: "/progress",
                params: {
                    id,
                },
            });
            if (data?.message) {
                const { time, content } = data?.message;
                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (!!data?.progress) {
                setProcessing(data?.progress);
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (processing === Progress.None) {
            // setProcessing(Progress.None);
            return
        }
        const interval = setInterval(() => {
            // if (processing === Progress.None) {
            //     clearInterval(interval);
            //     setProcessing(Progress.None);
            //     getResult({
            //         id: currentId,
            //     });
            //     getBacktest({
            //         id: currentId,
            //     });
            //     setCurrentId(1);
            //     return;
            // }

            if (processing === Progress.Done || processing === Progress.None) {
                clearInterval(interval);
                // setProcessing(Progress.None);
                getResult({
                    id: currentId,
                });
                getBacktest({
                    id: currentId,
                });
                // setCurrentId(1);
                setActiveTab("Backtest");
                return;
            }
            getProcess({
                id: currentId,
            });
            //  setSeconds(seconds => seconds + 1);
        }, 2000);
        return () => clearInterval(interval);
    }, [processing]);

    const postRun = async () => {
        try {
            const { inputs } = state.postRun;
            const obInput = {};
            inputs.forEach((input) => {
                const { name, start, stop, step, value } = input;
                obInput[name] = {
                    start,
                    stop,
                    step,
                    value,
                };
            });
            const leverageConverted = Number(
                state.postRun.leverage.split(":")[1]
            );
            const { data } = await axios({
                method: "post",
                url: "/run",
                data: {
                    ...state.postRun,
                    leverage: leverageConverted,
                    inputs: {
                        ...obInput,
                    },
                    date: [
                        new Date(state.postRun.date[0]).getTime() / 1000,
                        new Date(state.postRun.date[1]).getTime() / 1000,
                    ],
                },
            });

            dispatch({
                type: CHANGE.RESULT,
                payload: {
                    data: initialState.result,
                },
            });

            dispatch({
                type: CHANGE.BACKTEST,
                payload: {
                    data: initialState.backtest,
                },
            });

            if (data?.message) {
                const { time, content } = data?.message;

                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (data?.id) {
                setProcessing(Progress.LoadData);
                setCurrentId(data?.id);
            }
        } catch (error) {}
    };

    const getInputs = async ({ expert }) => {
        try {
            const { data: inputs } = await axios({
                method: "get",
                url: "/getInputs",
                params: {
                    expert,
                },
            });
            if (inputs?.message) {
                const { time, content } = inputs?.message;

                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (!!inputs?.data?.inputs) {
                dispatch({
                    type: CHANGE.POSTRUN.INPUT_LIST,
                    payload: {
                        data: inputs?.data?.inputs
                        // NO need to convert anymore
                        // data: Object.keys(inputs?.data?.inputs).map((key) => {
                        //     const { value, start, step, stop, optimize } =
                        //         inputs?.data?.inputs[key];
                        //     return {
                        //         name: key,
                        //         value,
                        //         start,
                        //         step,
                        //         stop,
                        //         optimize,
                        //     };
                        // }),
                    },
                });
            }
        } catch (error) {}
    };
    const getProperties = async ({ symbol }) => {
        try {
            const { data } = await axios({
                method: "get",
                url: "/getProperties",
                params: {
                    symbol,
                },
            });
            if (data) {
                dispatch({
                    type: CHANGE.POSTRUN.PROPERTIES_LIST,
                    payload: {
                        data: data.properties,
                    },
                });
            }
        } catch (error) {}
    };

    const getResult = async ({ id = 1 }) => {
        try {
            const { data } = await axios({
                method: "get",
                url: "/result",
                params: {
                    id,
                },
            });
            if (data?.message) {
                const { time, content } = data?.message;

                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (!!data?.result) {
                dispatch({
                    type: CHANGE.RESULT,
                    payload: {
                        data: data?.result,
                        // data: [...Array(10000)].map((u, i) => ({
                        //     id: i,
                        //     time: "2022-08-12 10:30:01:23",
                        //     type: "sell",
                        //     order: 1,
                        //     size: 0.2,
                        //     price: 1.02344,
                        //     sl: 1.02322,
                        //     tp: 1.02355,
                        //     profit: 0,
                        //     balance: 0,
                        // })),
                    },
                });
            }
        } catch (error) {}
    };

    const getBacktest = async ({ id = 1 }) => {
        try {
            const { data } = await axios({
                method: "get",
                url: "/backtest",
                params: {
                    id,
                },
            });
            if (data?.message) {
                const { time, content } = data?.message;

                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (!!data?.backtest) {
                dispatch({
                    type: CHANGE.BACKTEST,
                    payload: {
                        data: data?.backtest,
                    },
                });
                setUpdateChart((x) => !x);
            }
        } catch (error) {}
    };

    const getSyncSettings = async () => {
        try {
            const { data } = await axios({
                method: "get",
                url: "/syncSettings",
            });
            if (data?.message) {
                const { time, content } = data?.message;

                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (data?.status === 0) {
                getSettings();
                // dispatch({
                //     type: CHANGE.BACKTEST,
                //     payload: {
                //         data: data?.data,
                //     },
                // });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const getOpenIde = async () => {
        console.log("getOpenIde");
        try {
            const { data } = await axios({
                method: "get",
                url: "/openIde",
            });
            if (data?.message) {
                const { time, content } = data?.message;

                dispatch({
                    type: CHANGE.JOURNAL,
                    payload: {
                        data: {
                            time,
                            content,
                        },
                    },
                });
            }
            if (!!data?.data && data.data.status === 0) {
                // getSettings();
                // dispatch({
                //     type: CHANGE.BACKTEST,
                //     payload: {
                //         data: data?.data,
                //     },
                // });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const getJournal = async () => {
        // try {
        //     const { data } = await axios({
        //         method: "get",
        //         url: "/journal",
        //     });
        //     if (!!data?.data) {
        //         console.log("getJournal", data.data);
        //         dispatch({
        //             type: CHANGE.JOURNAL,
        //             payload: {
        //                 data: data?.data,
        //             },
        //         });
        //     }
        // } catch (error) {
        //     console.log("error", error);
        // }
    };

    const onClickFocus = (index) => {
        setFocus(index);
        // clear focus khi switch tab
        index !== -1 && setActiveTab("Result");
    };

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                state,
                dispatch,
                getResult,
                getInputs,
                getProperties,
                postRun,
                processing,
                updateChart,
                onClickFocus,
                focus,
                getSyncSettings,
                getOpenIde,
                getJournal,
                activeTab,
                setActiveTab,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = () => {
    const globalContext = useContext(GlobalContext);
    return globalContext;
};

import React from "react";
import "./YTimepicker.scss";

const YTimePicker = (props) => {
    const { defaultValue, className } = props;
    return (
        <input
            {...props}
            className={"ytimepicker " + (className ? className : "")}
            // type="datetime-local"
            id="YTimePicker"
            name="meeting-time"
            // value={defaultValue}
            // min="2018-06-07T00:00"
            // max="2018-06-14T00:00"
        />
    );
};

export default YTimePicker;

// export const SETTINGS = {
//     EXPERTS: "experts",
//     SYMBOL: "symbol",
// };
export const SETTINGS = {
    EXPERT: "SETTINGS_EXPERTS",
};
export const CHANGE = {
    RESULT: "result",
    BACKTEST: "backtest",
    SETTINGS: {
        EXPERT: "SETTINGS_EXPERTS",
        SYMBOL: "SETTINGS_SYMBOL",
    },
    POSTRUN: {
        EXPERT: "expert",
        SYMBOL: "symbol",
        TIMEFRAME: "timeframe",
        DATE: "date",
        DELAY: "delay",
        MODELING: "modeling",
        DEPOSIT: "deposit",
        LEVERAGE: "leverage",
        OPTIMIZATION: "optimization",
        INPUTS: "inputs",
        INPUT_LIST: "inputs_list",
        INDEX: "postRun",
        PROPERTIES: "properties",
        PROPERTIES_LIST: "properties_list",
    },
    JOURNAL: "journal",
};

export const splitList2Page = ({ list, pageSize }) => {
    const length = list.length;
    let result = [];
    let count = 0;
    let page = [];
    list.forEach((item, index) => {
        page.push(item);
        count++;
        if (count === pageSize || index === length - 1) {
            result.push(page);
            page = [];
            count = 0;
        }
    });
    return result;
};

console.log(
    "splitList2Page({  })",
    splitList2Page({
        list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        pageSize: 2,
    })
);

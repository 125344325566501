import { CHANGE } from "../contants";

export const initialState = {
    settings: {
        experts: [],
        // symbol: ["EURUSD", "GBPUSD"],
        symbol: [],
    },
    postRun: {
        expert: "bollinger_band",
        symbol: "EURUSD",
        timeframe: "H1",
        date: [0, 0],
        delay: 0,
        modeling: "every tick based on real tick",
        deposit: {
            value: 10000,
            type: "USD",
        },
        leverage: "1:5",
        optimization: "Disabled",
        inputs: [],
        properties: {
            common: {
                digits: 5, // type: number
                baseCurrency: "",
                profitCurrency: "",
                marginCurrency: "",
                spread: 0, // type: number, default: 0
                chartMode: "By bid price",
                startDate: "2022-06-01 01:00:00.157000+00:00", // display directly, no need convert
                endDate: "2022-06-01 01:00:18.897000+00:00",
            },
            trade: {
                contractSize: 100000.0,
                calculation: "Forex",
                commission: 7,
                minimalVolume: 0,
                maximalVolume: 0,
                volumeStep: 0,
                volumeLimit: 0,
            },
            swaps: {
                swapType: null,
                swapLong: 0,
                swapShort: 0,
            },
            //swap rate
            swapRate: {
                sunday: 0.0,
                monday: 0.0,
                tuesday: 0.0,
                wednesday: 0.0,
                thursday: 0.0,
                friday: 0.0,
                saturday: 0.0,
            },
            //session // only trade, remote quote
            session: {
                sunday: [], // start, end time (in hour, minute, second)
                monday: ["00:00", "23:59"],
                tuesday: ["00:00", "23:59"],
                wednesday: ["00:00", "23:59"],
                thursday: ["00:00", "23:59"],
                friday: ["00:00", "23:59"],
                saturday: [],
            },
        },
    },
    progress: {
        status: 0,
        errorMessage: "",
        id: 1,
        progress: 4,
    },
    result: [],
    backtest: {
        progress: 0,
        bars: null,
        initialDeposit: null,
        grossProfit: null,
        grossLoss: null,
        totalTrades: null,
        totalDeals: null,
        ticks: null,
        balanceDrawdownMaximum: "",
        expectedPayoff: null,
        sharpeRatio: null,
        shortTrades: "",
        profitTrades: "",
        consecutiveWins: "",
        symbol: "",
        equityDrawdownMaximal: "",
        profitFactor: null,
        recoveryFactor: null,
        longTrades: "",
        lossTrades: "",
        consecutiveLosses: "",
        chart: {
            balances: [],
            time: [],
            // balances: [
            //     35464.719, 35283.239, 35510.249, 35377.729, 35409.239,
            //     35141.709, 34868.209, 35005.779, 34707.269, 34928.799,
            //     34620.229, 34793.279, 34783.499, 34281.499, 34140.699,
            //     34032.729, 34375.219, 34754.499, 34993.249, 34976.289,
            //     34480.739, 34575.769, 33705.249, 34258.769, 33995.299,
            //     34206.799, 34539.229, 34782.279, 34764.259, 34388.259,
            //     34360.789, 34519.199, 35036.759, 35278.739, 35282.239,
            //     35441.199, 35509.779, 35590.269, 35701.779, 35752.279,
            //     35799.219, 35553.219, 35660.269, 35871.269, 35847.289,
            //     36000.259, 36121.259, 36094.729, 36298.749, 36359.789,
            //     36242.999, 36096.749, 35950.699, 36075.229, 36080.289,
            //     36139.699, 35933.769, 36008.999, 35712.259, 35590.249,
            //     35803.779, 35945.769, 35237.199, 35157.249, 34720.249,
            //     34644.739, 34345.799, 34683.699, 34814.999, 35418.269,
            //     35783.269, 35722.249, 35748.269, 36093.199, 35707.279,
            //     35543.209, 36030.749, 35866.279, 34950.749, 35213.699,
            //     35487.779, 35778.239, 35914.749, 36299.209, 36423.299,
            //     36485.209, 36350.229, 36456.289, 36631.229, 36730.799,
            //     36325.229, 36311.299, 36219.759, 36054.269, 36299.269,
            //     36227.259, 36190.499, 35892.269, 35846.219, 35192.219,
            //     35205.269, 34712.219, 34456.279, 34133.289, 34327.709,
            //     33768.499, 34308.289, 34763.739, 35065.729, 35401.729,
            //     35569.719, 35316.719, 35089.779, 35102.199, 35568.279,
            //     35787.239, 35055.199, 34780.729, 34500.289, 34964.729,
            //     34824.259, 34455.789, 34343.226, 33626.789, 33695.719,
            //     32375.699, 33064.719, 33507.999, 33977.779, 33328.209,
            //     33963.779, 33646.229, 33325.299, 32500.249, 32785.269,
            //     33205.769, 33222.729, 33138.769, 32944.769, 33609.999,
            //     34017.729, 34359.259, 34603.219, 34510.719, 34881.279,
            //     34474.279, 34754.719, 34738.799, 35038.289, 35290.199,
            //     35245.739, 34783.289, 34869.229, 34894.799, 34634.789,
            //     34365.709, 34616.199, 34567.279, 34178.259, 34452.229,
            //     34641.499, 34393.249, 34478.699, 34894.289, 35283.719,
            //     34703.209, 33549.499, 34087.279, 33480.739, 33431.747,
            //     33935.999, 33065.209, 33162.729, 33162.719, 34087.259,
            //     32994.739, 32520.729, 32391.259, 32234.299, 31681.209,
            //     31981.729, 32064.769, 32332.749, 32632.729, 31453.739,
            //     31443.239, 31510.197, 31625.749, 32021.999, 32058.759,
            //     32574.759, 33339.249, 33207.749, 33078.999, 32810.999,
            //     33281.199, 33041.779, 32690.779, 33112.209, 32837.769,
            //     32288.219, 31012.239, 35464.719, 35283.239, 35510.249,
            //     35377.729, 35409.239, 35141.709, 34868.209, 35005.779,
            //     34707.269, 34928.799, 34620.229, 34793.279, 34783.499,
            //     34281.499, 34140.699, 34032.729, 34375.219, 34754.499,
            //     34993.249, 34976.289, 34480.739, 34575.769, 33705.249,
            //     34258.769, 33995.299, 34206.799, 34539.229, 34782.279,
            //     34764.259, 34388.259, 34360.789, 34519.199, 35036.759,
            //     35278.739, 35282.239, 35441.199, 35509.779, 35590.269,
            //     35701.779, 35752.279, 35799.219, 35553.219, 35660.269,
            //     35871.269, 35847.289, 36000.259, 36121.259, 36094.729,
            //     36298.749, 36359.789, 36242.999, 36096.749, 35950.699,
            //     36075.229, 36080.289, 36139.699, 35933.769, 36008.999,
            //     35712.259, 35590.249, 35803.779, 35945.769, 35237.199,
            //     35157.249, 34720.249, 34644.739, 34345.799, 34683.699,
            //     34814.999, 35418.269, 35783.269, 35722.249, 35748.269,
            //     36093.199, 35707.279, 35543.209, 36030.749, 35866.279,
            //     34950.749, 35213.699, 35487.779, 35778.239, 35914.749,
            //     36299.209, 36423.299, 36485.209, 36350.229, 36456.289,
            //     36631.229, 36730.799, 36325.229, 36311.299, 36219.759,
            //     36054.269, 36299.269, 36227.259, 36190.499, 35892.269,
            //     35846.219, 35192.219, 35205.269, 34712.219, 34456.279,
            //     34133.289, 34327.709, 33768.499, 34308.289, 34763.739,
            //     35065.729, 35401.729, 35569.719, 35316.719, 35089.779,
            //     35102.199, 35568.279, 35787.239, 35055.199, 34780.729,
            //     34500.289, 34964.729, 34824.259, 34455.789, 34343.226,
            //     33626.789, 33695.719, 32375.699, 33064.719, 33507.999,
            //     33977.779, 33328.209, 33963.779, 33646.229, 33325.299,
            //     32500.249, 32785.269, 33205.769, 33222.729, 33138.769,
            //     32944.769, 33609.999, 34017.729, 34359.259, 34603.219,
            //     34510.719, 34881.279, 34474.279, 34754.719, 34738.799,
            //     35038.289, 35290.199, 35245.739, 34783.289, 34869.229,
            //     34894.799, 34634.789, 34365.709, 34616.199, 34567.279,
            //     34178.259, 34452.229, 34641.499, 34393.249, 34478.699,
            //     34894.289, 35283.719, 34703.209, 33549.499, 34087.279,
            //     33480.739, 33431.747, 33935.999, 33065.209, 33162.729,
            //     33162.719, 34087.259, 32994.739, 32520.729, 32391.259,
            //     32234.299, 31681.209, 31981.729, 32064.769, 32332.749,
            //     32632.729, 31453.739, 31443.239, 31510.197, 31625.749,
            //     32021.999, 32058.759, 32574.759, 33339.249, 33207.749,
            //     33078.999, 32810.999, 33281.199, 33041.779, 32690.779,
            //     33112.209, 32837.769, 32288.219, 31012.239, 35464.719,
            //     35283.239, 35510.249, 35377.729, 35409.239, 35141.709,
            //     34868.209, 35005.779, 34707.269, 34928.799, 34620.229,
            //     34793.279, 34783.499, 34281.499, 34140.699, 34032.729,
            //     34375.219, 34754.499, 34993.249, 34976.289, 34480.739,
            //     34575.769, 33705.249, 34258.769, 33995.299, 34206.799,
            //     34539.229, 34782.279, 34764.259, 34388.259, 34360.789,
            //     34519.199, 35036.759, 35278.739, 35282.239, 35441.199,
            //     35509.779, 35590.269, 35701.779, 35752.279, 35799.219,
            //     35553.219, 35660.269, 35871.269, 35847.289, 36000.259,
            //     36121.259, 36094.729, 36298.749, 36359.789, 36242.999,
            //     36096.749, 35950.699, 36075.229, 36080.289, 36139.699,
            //     35933.769, 36008.999, 35712.259, 35590.249, 35803.779,
            //     35945.769, 35237.199, 35157.249, 34720.249, 34644.739,
            //     34345.799, 34683.699, 34814.999, 35418.269, 35783.269,
            //     35722.249, 35748.269, 36093.199, 35707.279, 35543.209,
            //     36030.749, 35866.279, 34950.749, 35213.699, 35487.779,
            //     35778.239, 35914.749, 36299.209, 36423.299, 36485.209,
            //     36350.229, 36456.289, 36631.229, 36730.799, 36325.229,
            //     36311.299, 36219.759, 36054.269, 36299.269, 36227.259,
            //     36190.499, 35892.269, 35846.219, 35192.219, 35205.269,
            //     34712.219, 34456.279, 34133.289, 34327.709, 33768.499,
            //     34308.289, 34763.739, 35065.729, 35401.729, 35569.719,
            //     35316.719, 35089.779, 35102.199, 35568.279, 35787.239,
            //     35055.199, 34780.729, 34500.289, 34964.729, 34824.259,
            //     34455.789, 34343.226, 33626.789, 33695.719, 32375.699,
            //     33064.719, 33507.999, 33977.779, 33328.209, 33963.779,
            //     33646.229, 33325.299, 32500.249, 32785.269, 33205.769,
            //     33222.729, 33138.769, 32944.769, 33609.999, 34017.729,
            //     34359.259, 34603.219, 34510.719, 34881.279, 34474.279,
            //     34754.719, 34738.799, 35038.289, 35290.199, 35245.739,
            //     34783.289, 34869.229, 34894.799, 34634.789, 34365.709,
            //     34616.199, 34567.279, 34178.259, 34452.229, 34641.499,
            //     34393.249, 34478.699, 34894.289, 35283.719, 34703.209,
            //     33549.499, 34087.279, 33480.739, 33431.747, 33935.999,
            //     33065.209, 33162.729, 33162.719, 34087.259, 32994.739,
            //     32520.729, 32391.259, 32234.299, 31681.209, 31981.729,
            //     32064.769, 32332.749, 32632.729, 31453.739, 31443.239,
            //     31510.197, 31625.749, 32021.999, 32058.759, 32574.759,
            //     33339.249, 33207.749, 33078.999, 32810.999, 33281.199,
            //     33041.779, 32690.779, 33112.209, 32837.769, 32288.219,
            //     31012.239, 35464.719, 35283.239, 35510.249, 35377.729,
            //     35409.239, 35141.709, 34868.209, 35005.779, 34707.269,
            //     34928.799, 34620.229, 34793.279, 34783.499, 34281.499,
            //     34140.699, 34032.729, 34375.219, 34754.499, 34993.249,
            //     34976.289, 34480.739, 34575.769, 33705.249, 34258.769,
            //     33995.299, 34206.799, 34539.229, 34782.279, 34764.259,
            //     34388.259, 34360.789, 34519.199, 35036.759, 35278.739,
            //     35282.239, 35441.199, 35509.779, 35590.269, 35701.779,
            //     35752.279, 35799.219, 35553.219, 35660.269, 35871.269,
            //     35847.289, 36000.259, 36121.259, 36094.729, 36298.749,
            //     36359.789, 36242.999, 36096.749, 35950.699, 36075.229,
            //     36080.289, 36139.699, 35933.769, 36008.999, 35712.259,
            //     35590.249, 35803.779, 35945.769, 35237.199, 35157.249,
            //     34720.249, 34644.739, 34345.799, 34683.699, 34814.999,
            //     35418.269, 35783.269, 35722.249, 35748.269, 36093.199,
            //     35707.279, 35543.209, 36030.749, 35866.279, 34950.749,
            //     35213.699, 35487.779, 35778.239, 35914.749, 36299.209,
            //     36423.299, 36485.209, 36350.229, 36456.289, 36631.229,
            //     36730.799, 36325.229, 36311.299, 36219.759, 36054.269,
            //     36299.269, 36227.259, 36190.499, 35892.269, 35846.219,
            //     35192.219, 35205.269, 34712.219, 34456.279, 34133.289,
            //     34327.709, 33768.499, 34308.289, 34763.739, 35065.729,
            //     35401.729, 35569.719, 35316.719, 35089.779, 35102.199,
            //     35568.279, 35787.239, 35055.199, 34780.729, 34500.289,
            //     34964.729, 34824.259, 34455.789, 34343.226, 33626.789,
            //     33695.719, 32375.699, 33064.719, 33507.999, 33977.779,
            //     33328.209, 33963.779, 33646.229, 33325.299, 32500.249,
            //     32785.269, 33205.769, 33222.729, 33138.769, 32944.769,
            //     33609.999, 34017.729, 34359.259, 34603.219, 34510.719,
            //     34881.279, 34474.279, 34754.719, 34738.799, 35038.289,
            //     35290.199, 35245.739, 34783.289, 34869.229, 34894.799,
            //     34634.789, 34365.709, 34616.199, 34567.279, 34178.259,
            //     34452.229, 34641.499, 34393.249, 34478.699, 34894.289,
            //     35283.719, 34703.209, 33549.499, 34087.279, 33480.739,
            //     33431.747, 33935.999, 33065.209, 33162.729, 33162.719,
            //     34087.259, 32994.739, 32520.729, 32391.259, 32234.299,
            //     31681.209, 31981.729, 32064.769, 32332.749, 32632.729,
            //     31453.739, 31443.239, 31510.197, 31625.749, 32021.999,
            //     32058.759, 32574.759, 33339.249, 33207.749, 33078.999,
            //     32810.999, 33281.199, 33041.779, 32690.779, 33112.209,
            //     32837.769, 32288.219, 31012.239,
            // ],
            // time: [
            //     "2021-09-01",
            //     "2021-09-02",
            //     "2021-09-03",
            //     "2021-09-06",
            //     "2021-09-07",
            //     "2021-09-08",
            //     "2021-09-09",
            //     "2021-09-10",
            //     "2021-09-13",
            //     "2021-09-14",
            //     "2021-09-15",
            //     "2021-09-16",
            //     "2021-09-17",
            //     "2021-09-20",
            //     "2021-09-21",
            //     "2021-09-22",
            //     "2021-09-23",
            //     "2021-09-24",
            //     "2021-09-27",
            //     "2021-09-28",
            //     "2021-09-29",
            //     "2021-09-30",
            //     "2021-10-01",
            //     "2021-10-04",
            //     "2021-10-05",
            //     "2021-10-06",
            //     "2021-10-07",
            //     "2021-10-08",
            //     "2021-10-11",
            //     "2021-10-12",
            //     "2021-10-13",
            //     "2021-10-14",
            //     "2021-10-15",
            //     "2021-10-18",
            //     "2021-10-19",
            //     "2021-10-20",
            //     "2021-10-21",
            //     "2021-10-22",
            //     "2021-10-25",
            //     "2021-10-26",
            //     "2021-10-27",
            //     "2021-10-28",
            //     "2021-10-29",
            //     "2021-11-01",
            //     "2021-11-02",
            //     "2021-11-03",
            //     "2021-11-04",
            //     "2021-11-05",
            //     "2021-11-08",
            //     "2021-11-09",
            //     "2021-11-10",
            //     "2021-11-11",
            //     "2021-11-12",
            //     "2021-11-15",
            //     "2021-11-16",
            //     "2021-11-17",
            //     "2021-11-18",
            //     "2021-11-19",
            //     "2021-11-22",
            //     "2021-11-23",
            //     "2021-11-24",
            //     "2021-11-25",
            //     "2021-11-26",
            //     "2021-11-29",
            //     "2021-11-30",
            //     "2021-12-01",
            //     "2021-12-02",
            //     "2021-12-03",
            //     "2021-12-06",
            //     "2021-12-07",
            //     "2021-12-08",
            //     "2021-12-09",
            //     "2021-12-10",
            //     "2021-12-13",
            //     "2021-12-14",
            //     "2021-12-15",
            //     "2021-12-16",
            //     "2021-12-17",
            //     "2021-12-20",
            //     "2021-12-21",
            //     "2021-12-22",
            //     "2021-12-23",
            //     "2021-12-27",
            //     "2021-12-28",
            //     "2021-12-29",
            //     "2021-12-30",
            //     "2021-12-31",
            //     "2022-01-03",
            //     "2022-01-04",
            //     "2022-01-05",
            //     "2022-01-06",
            //     "2022-01-07",
            //     "2022-01-10",
            //     "2022-01-11",
            //     "2022-01-12",
            //     "2022-01-13",
            //     "2022-01-14",
            //     "2022-01-17",
            //     "2022-01-18",
            //     "2022-01-19",
            //     "2022-01-20",
            //     "2022-01-21",
            //     "2022-01-24",
            //     "2022-01-25",
            //     "2022-01-26",
            //     "2022-01-27",
            //     "2022-01-28",
            //     "2022-01-31",
            //     "2022-02-01",
            //     "2022-02-02",
            //     "2022-02-03",
            //     "2022-02-04",
            //     "2022-02-07",
            //     "2022-02-08",
            //     "2022-02-09",
            //     "2022-02-10",
            //     "2022-02-11",
            //     "2022-02-14",
            //     "2022-02-15",
            //     "2022-02-16",
            //     "2022-02-17",
            //     "2022-02-18",
            //     "2022-02-21",
            //     "2022-02-22",
            //     "2022-02-23",
            //     "2022-02-24",
            //     "2022-02-25",
            //     "2022-02-28",
            //     "2022-03-01",
            //     "2022-03-02",
            //     "2022-03-03",
            //     "2022-03-04",
            //     "2022-03-07",
            //     "2022-03-08",
            //     "2022-03-09",
            //     "2022-03-10",
            //     "2022-03-11",
            //     "2022-03-14",
            //     "2022-03-15",
            //     "2022-03-16",
            //     "2022-03-17",
            //     "2022-03-18",
            //     "2022-03-21",
            //     "2022-03-22",
            //     "2022-03-23",
            //     "2022-03-24",
            //     "2022-03-25",
            //     "2022-03-28",
            //     "2022-03-29",
            //     "2022-03-30",
            //     "2022-03-31",
            //     "2022-04-01",
            //     "2022-04-04",
            //     "2022-04-05",
            //     "2022-04-06",
            //     "2022-04-07",
            //     "2022-04-08",
            //     "2022-04-11",
            //     "2022-04-12",
            //     "2022-04-13",
            //     "2022-04-14",
            //     "2022-04-18",
            //     "2022-04-19",
            //     "2022-04-20",
            //     "2022-04-21",
            //     "2022-04-22",
            //     "2022-04-25",
            //     "2022-04-26",
            //     "2022-04-27",
            //     "2022-04-28",
            //     "2022-04-29",
            //     "2022-05-02",
            //     "2022-05-03",
            //     "2022-05-04",
            //     "2022-05-05",
            //     "2022-05-06",
            //     "2022-05-09",
            //     "2022-05-10",
            //     "2022-05-11",
            //     "2022-05-12",
            //     "2022-05-13",
            //     "2022-05-16",
            //     "2022-05-17",
            //     "2022-05-18",
            //     "2022-05-19",
            //     "2022-05-20",
            //     "2022-05-23",
            //     "2022-05-24",
            //     "2022-05-25",
            //     "2022-05-26",
            //     "2022-05-27",
            //     "2022-05-30",
            //     "2022-05-31",
            //     "2022-06-01",
            //     "2022-06-02",
            //     "2022-06-03",
            //     "2022-06-06",
            //     "2022-06-07",
            //     "2022-06-08",
            //     "2022-06-09",
            //     "2022-06-10",
            //     "2022-06-13",
            //     "2021-09-01",
            //     "2021-09-02",
            //     "2021-09-03",
            //     "2021-09-06",
            //     "2021-09-07",
            //     "2021-09-08",
            //     "2021-09-09",
            //     "2021-09-10",
            //     "2021-09-13",
            //     "2021-09-14",
            //     "2021-09-15",
            //     "2021-09-16",
            //     "2021-09-17",
            //     "2021-09-20",
            //     "2021-09-21",
            //     "2021-09-22",
            //     "2021-09-23",
            //     "2021-09-24",
            //     "2021-09-27",
            //     "2021-09-28",
            //     "2021-09-29",
            //     "2021-09-30",
            //     "2021-10-01",
            //     "2021-10-04",
            //     "2021-10-05",
            //     "2021-10-06",
            //     "2021-10-07",
            //     "2021-10-08",
            //     "2021-10-11",
            //     "2021-10-12",
            //     "2021-10-13",
            //     "2021-10-14",
            //     "2021-10-15",
            //     "2021-10-18",
            //     "2021-10-19",
            //     "2021-10-20",
            //     "2021-10-21",
            //     "2021-10-22",
            //     "2021-10-25",
            //     "2021-10-26",
            //     "2021-10-27",
            //     "2021-10-28",
            //     "2021-10-29",
            //     "2021-11-01",
            //     "2021-11-02",
            //     "2021-11-03",
            //     "2021-11-04",
            //     "2021-11-05",
            //     "2021-11-08",
            //     "2021-11-09",
            //     "2021-11-10",
            //     "2021-11-11",
            //     "2021-11-12",
            //     "2021-11-15",
            //     "2021-11-16",
            //     "2021-11-17",
            //     "2021-11-18",
            //     "2021-11-19",
            //     "2021-11-22",
            //     "2021-11-23",
            //     "2021-11-24",
            //     "2021-11-25",
            //     "2021-11-26",
            //     "2021-11-29",
            //     "2021-11-30",
            //     "2021-12-01",
            //     "2021-12-02",
            //     "2021-12-03",
            //     "2021-12-06",
            //     "2021-12-07",
            //     "2021-12-08",
            //     "2021-12-09",
            //     "2021-12-10",
            //     "2021-12-13",
            //     "2021-12-14",
            //     "2021-12-15",
            //     "2021-12-16",
            //     "2021-12-17",
            //     "2021-12-20",
            //     "2021-12-21",
            //     "2021-12-22",
            //     "2021-12-23",
            //     "2021-12-27",
            //     "2021-12-28",
            //     "2021-12-29",
            //     "2021-12-30",
            //     "2021-12-31",
            //     "2022-01-03",
            //     "2022-01-04",
            //     "2022-01-05",
            //     "2022-01-06",
            //     "2022-01-07",
            //     "2022-01-10",
            //     "2022-01-11",
            //     "2022-01-12",
            //     "2022-01-13",
            //     "2022-01-14",
            //     "2022-01-17",
            //     "2022-01-18",
            //     "2022-01-19",
            //     "2022-01-20",
            //     "2022-01-21",
            //     "2022-01-24",
            //     "2022-01-25",
            //     "2022-01-26",
            //     "2022-01-27",
            //     "2022-01-28",
            //     "2022-01-31",
            //     "2022-02-01",
            //     "2022-02-02",
            //     "2022-02-03",
            //     "2022-02-04",
            //     "2022-02-07",
            //     "2022-02-08",
            //     "2022-02-09",
            //     "2022-02-10",
            //     "2022-02-11",
            //     "2022-02-14",
            //     "2022-02-15",
            //     "2022-02-16",
            //     "2022-02-17",
            //     "2022-02-18",
            //     "2022-02-21",
            //     "2022-02-22",
            //     "2022-02-23",
            //     "2022-02-24",
            //     "2022-02-25",
            //     "2022-02-28",
            //     "2022-03-01",
            //     "2022-03-02",
            //     "2022-03-03",
            //     "2022-03-04",
            //     "2022-03-07",
            //     "2022-03-08",
            //     "2022-03-09",
            //     "2022-03-10",
            //     "2022-03-11",
            //     "2022-03-14",
            //     "2022-03-15",
            //     "2022-03-16",
            //     "2022-03-17",
            //     "2022-03-18",
            //     "2022-03-21",
            //     "2022-03-22",
            //     "2022-03-23",
            //     "2022-03-24",
            //     "2022-03-25",
            //     "2022-03-28",
            //     "2022-03-29",
            //     "2022-03-30",
            //     "2022-03-31",
            //     "2022-04-01",
            //     "2022-04-04",
            //     "2022-04-05",
            //     "2022-04-06",
            //     "2022-04-07",
            //     "2022-04-08",
            //     "2022-04-11",
            //     "2022-04-12",
            //     "2022-04-13",
            //     "2022-04-14",
            //     "2022-04-18",
            //     "2022-04-19",
            //     "2022-04-20",
            //     "2022-04-21",
            //     "2022-04-22",
            //     "2022-04-25",
            //     "2022-04-26",
            //     "2022-04-27",
            //     "2022-04-28",
            //     "2022-04-29",
            //     "2022-05-02",
            //     "2022-05-03",
            //     "2022-05-04",
            //     "2022-05-05",
            //     "2022-05-06",
            //     "2022-05-09",
            //     "2022-05-10",
            //     "2022-05-11",
            //     "2022-05-12",
            //     "2022-05-13",
            //     "2022-05-16",
            //     "2022-05-17",
            //     "2022-05-18",
            //     "2022-05-19",
            //     "2022-05-20",
            //     "2022-05-23",
            //     "2022-05-24",
            //     "2022-05-25",
            //     "2022-05-26",
            //     "2022-05-27",
            //     "2022-05-30",
            //     "2022-05-31",
            //     "2022-06-01",
            //     "2022-06-02",
            //     "2022-06-03",
            //     "2022-06-06",
            //     "2022-06-07",
            //     "2022-06-08",
            //     "2022-06-09",
            //     "2022-06-10",
            //     "2022-06-13",
            //     "2021-09-01",
            //     "2021-09-02",
            //     "2021-09-03",
            //     "2021-09-06",
            //     "2021-09-07",
            //     "2021-09-08",
            //     "2021-09-09",
            //     "2021-09-10",
            //     "2021-09-13",
            //     "2021-09-14",
            //     "2021-09-15",
            //     "2021-09-16",
            //     "2021-09-17",
            //     "2021-09-20",
            //     "2021-09-21",
            //     "2021-09-22",
            //     "2021-09-23",
            //     "2021-09-24",
            //     "2021-09-27",
            //     "2021-09-28",
            //     "2021-09-29",
            //     "2021-09-30",
            //     "2021-10-01",
            //     "2021-10-04",
            //     "2021-10-05",
            //     "2021-10-06",
            //     "2021-10-07",
            //     "2021-10-08",
            //     "2021-10-11",
            //     "2021-10-12",
            //     "2021-10-13",
            //     "2021-10-14",
            //     "2021-10-15",
            //     "2021-10-18",
            //     "2021-10-19",
            //     "2021-10-20",
            //     "2021-10-21",
            //     "2021-10-22",
            //     "2021-10-25",
            //     "2021-10-26",
            //     "2021-10-27",
            //     "2021-10-28",
            //     "2021-10-29",
            //     "2021-11-01",
            //     "2021-11-02",
            //     "2021-11-03",
            //     "2021-11-04",
            //     "2021-11-05",
            //     "2021-11-08",
            //     "2021-11-09",
            //     "2021-11-10",
            //     "2021-11-11",
            //     "2021-11-12",
            //     "2021-11-15",
            //     "2021-11-16",
            //     "2021-11-17",
            //     "2021-11-18",
            //     "2021-11-19",
            //     "2021-11-22",
            //     "2021-11-23",
            //     "2021-11-24",
            //     "2021-11-25",
            //     "2021-11-26",
            //     "2021-11-29",
            //     "2021-11-30",
            //     "2021-12-01",
            //     "2021-12-02",
            //     "2021-12-03",
            //     "2021-12-06",
            //     "2021-12-07",
            //     "2021-12-08",
            //     "2021-12-09",
            //     "2021-12-10",
            //     "2021-12-13",
            //     "2021-12-14",
            //     "2021-12-15",
            //     "2021-12-16",
            //     "2021-12-17",
            //     "2021-12-20",
            //     "2021-12-21",
            //     "2021-12-22",
            //     "2021-12-23",
            //     "2021-12-27",
            //     "2021-12-28",
            //     "2021-12-29",
            //     "2021-12-30",
            //     "2021-12-31",
            //     "2022-01-03",
            //     "2022-01-04",
            //     "2022-01-05",
            //     "2022-01-06",
            //     "2022-01-07",
            //     "2022-01-10",
            //     "2022-01-11",
            //     "2022-01-12",
            //     "2022-01-13",
            //     "2022-01-14",
            //     "2022-01-17",
            //     "2022-01-18",
            //     "2022-01-19",
            //     "2022-01-20",
            //     "2022-01-21",
            //     "2022-01-24",
            //     "2022-01-25",
            //     "2022-01-26",
            //     "2022-01-27",
            //     "2022-01-28",
            //     "2022-01-31",
            //     "2022-02-01",
            //     "2022-02-02",
            //     "2022-02-03",
            //     "2022-02-04",
            //     "2022-02-07",
            //     "2022-02-08",
            //     "2022-02-09",
            //     "2022-02-10",
            //     "2022-02-11",
            //     "2022-02-14",
            //     "2022-02-15",
            //     "2022-02-16",
            //     "2022-02-17",
            //     "2022-02-18",
            //     "2022-02-21",
            //     "2022-02-22",
            //     "2022-02-23",
            //     "2022-02-24",
            //     "2022-02-25",
            //     "2022-02-28",
            //     "2022-03-01",
            //     "2022-03-02",
            //     "2022-03-03",
            //     "2022-03-04",
            //     "2022-03-07",
            //     "2022-03-08",
            //     "2022-03-09",
            //     "2022-03-10",
            //     "2022-03-11",
            //     "2022-03-14",
            //     "2022-03-15",
            //     "2022-03-16",
            //     "2022-03-17",
            //     "2022-03-18",
            //     "2022-03-21",
            //     "2022-03-22",
            //     "2022-03-23",
            //     "2022-03-24",
            //     "2022-03-25",
            //     "2022-03-28",
            //     "2022-03-29",
            //     "2022-03-30",
            //     "2022-03-31",
            //     "2022-04-01",
            //     "2022-04-04",
            //     "2022-04-05",
            //     "2022-04-06",
            //     "2022-04-07",
            //     "2022-04-08",
            //     "2022-04-11",
            //     "2022-04-12",
            //     "2022-04-13",
            //     "2022-04-14",
            //     "2022-04-18",
            //     "2022-04-19",
            //     "2022-04-20",
            //     "2022-04-21",
            //     "2022-04-22",
            //     "2022-04-25",
            //     "2022-04-26",
            //     "2022-04-27",
            //     "2022-04-28",
            //     "2022-04-29",
            //     "2022-05-02",
            //     "2022-05-03",
            //     "2022-05-04",
            //     "2022-05-05",
            //     "2022-05-06",
            //     "2022-05-09",
            //     "2022-05-10",
            //     "2022-05-11",
            //     "2022-05-12",
            //     "2022-05-13",
            //     "2022-05-16",
            //     "2022-05-17",
            //     "2022-05-18",
            //     "2022-05-19",
            //     "2022-05-20",
            //     "2022-05-23",
            //     "2022-05-24",
            //     "2022-05-25",
            //     "2022-05-26",
            //     "2022-05-27",
            //     "2022-05-30",
            //     "2022-05-31",
            //     "2022-06-01",
            //     "2022-06-02",
            //     "2022-06-03",
            //     "2022-06-06",
            //     "2022-06-07",
            //     "2022-06-08",
            //     "2022-06-09",
            //     "2022-06-10",
            //     "2022-06-13",
            //     "2021-09-01",
            //     "2021-09-02",
            //     "2021-09-03",
            //     "2021-09-06",
            //     "2021-09-07",
            //     "2021-09-08",
            //     "2021-09-09",
            //     "2021-09-10",
            //     "2021-09-13",
            //     "2021-09-14",
            //     "2021-09-15",
            //     "2021-09-16",
            //     "2021-09-17",
            //     "2021-09-20",
            //     "2021-09-21",
            //     "2021-09-22",
            //     "2021-09-23",
            //     "2021-09-24",
            //     "2021-09-27",
            //     "2021-09-28",
            //     "2021-09-29",
            //     "2021-09-30",
            //     "2021-10-01",
            //     "2021-10-04",
            //     "2021-10-05",
            //     "2021-10-06",
            //     "2021-10-07",
            //     "2021-10-08",
            //     "2021-10-11",
            //     "2021-10-12",
            //     "2021-10-13",
            //     "2021-10-14",
            //     "2021-10-15",
            //     "2021-10-18",
            //     "2021-10-19",
            //     "2021-10-20",
            //     "2021-10-21",
            //     "2021-10-22",
            //     "2021-10-25",
            //     "2021-10-26",
            //     "2021-10-27",
            //     "2021-10-28",
            //     "2021-10-29",
            //     "2021-11-01",
            //     "2021-11-02",
            //     "2021-11-03",
            //     "2021-11-04",
            //     "2021-11-05",
            //     "2021-11-08",
            //     "2021-11-09",
            //     "2021-11-10",
            //     "2021-11-11",
            //     "2021-11-12",
            //     "2021-11-15",
            //     "2021-11-16",
            //     "2021-11-17",
            //     "2021-11-18",
            //     "2021-11-19",
            //     "2021-11-22",
            //     "2021-11-23",
            //     "2021-11-24",
            //     "2021-11-25",
            //     "2021-11-26",
            //     "2021-11-29",
            //     "2021-11-30",
            //     "2021-12-01",
            //     "2021-12-02",
            //     "2021-12-03",
            //     "2021-12-06",
            //     "2021-12-07",
            //     "2021-12-08",
            //     "2021-12-09",
            //     "2021-12-10",
            //     "2021-12-13",
            //     "2021-12-14",
            //     "2021-12-15",
            //     "2021-12-16",
            //     "2021-12-17",
            //     "2021-12-20",
            //     "2021-12-21",
            //     "2021-12-22",
            //     "2021-12-23",
            //     "2021-12-27",
            //     "2021-12-28",
            //     "2021-12-29",
            //     "2021-12-30",
            //     "2021-12-31",
            //     "2022-01-03",
            //     "2022-01-04",
            //     "2022-01-05",
            //     "2022-01-06",
            //     "2022-01-07",
            //     "2022-01-10",
            //     "2022-01-11",
            //     "2022-01-12",
            //     "2022-01-13",
            //     "2022-01-14",
            //     "2022-01-17",
            //     "2022-01-18",
            //     "2022-01-19",
            //     "2022-01-20",
            //     "2022-01-21",
            //     "2022-01-24",
            //     "2022-01-25",
            //     "2022-01-26",
            //     "2022-01-27",
            //     "2022-01-28",
            //     "2022-01-31",
            //     "2022-02-01",
            //     "2022-02-02",
            //     "2022-02-03",
            //     "2022-02-04",
            //     "2022-02-07",
            //     "2022-02-08",
            //     "2022-02-09",
            //     "2022-02-10",
            //     "2022-02-11",
            //     "2022-02-14",
            //     "2022-02-15",
            //     "2022-02-16",
            //     "2022-02-17",
            //     "2022-02-18",
            //     "2022-02-21",
            //     "2022-02-22",
            //     "2022-02-23",
            //     "2022-02-24",
            //     "2022-02-25",
            //     "2022-02-28",
            //     "2022-03-01",
            //     "2022-03-02",
            //     "2022-03-03",
            //     "2022-03-04",
            //     "2022-03-07",
            //     "2022-03-08",
            //     "2022-03-09",
            //     "2022-03-10",
            //     "2022-03-11",
            //     "2022-03-14",
            //     "2022-03-15",
            //     "2022-03-16",
            //     "2022-03-17",
            //     "2022-03-18",
            //     "2022-03-21",
            //     "2022-03-22",
            //     "2022-03-23",
            //     "2022-03-24",
            //     "2022-03-25",
            //     "2022-03-28",
            //     "2022-03-29",
            //     "2022-03-30",
            //     "2022-03-31",
            //     "2022-04-01",
            //     "2022-04-04",
            //     "2022-04-05",
            //     "2022-04-06",
            //     "2022-04-07",
            //     "2022-04-08",
            //     "2022-04-11",
            //     "2022-04-12",
            //     "2022-04-13",
            //     "2022-04-14",
            //     "2022-04-18",
            //     "2022-04-19",
            //     "2022-04-20",
            //     "2022-04-21",
            //     "2022-04-22",
            //     "2022-04-25",
            //     "2022-04-26",
            //     "2022-04-27",
            //     "2022-04-28",
            //     "2022-04-29",
            //     "2022-05-02",
            //     "2022-05-03",
            //     "2022-05-04",
            //     "2022-05-05",
            //     "2022-05-06",
            //     "2022-05-09",
            //     "2022-05-10",
            //     "2022-05-11",
            //     "2022-05-12",
            //     "2022-05-13",
            //     "2022-05-16",
            //     "2022-05-17",
            //     "2022-05-18",
            //     "2022-05-19",
            //     "2022-05-20",
            //     "2022-05-23",
            //     "2022-05-24",
            //     "2022-05-25",
            //     "2022-05-26",
            //     "2022-05-27",
            //     "2022-05-30",
            //     "2022-05-31",
            //     "2022-06-01",
            //     "2022-06-02",
            //     "2022-06-03",
            //     "2022-06-06",
            //     "2022-06-07",
            //     "2022-06-08",
            //     "2022-06-09",
            //     "2022-06-10",
            //     "2022-06-13",
            // ],
        },
    },
    journal: [],
};

export const Progress = {
    None: -1,
    Init: 0,
    LoadData: 1,
    InitStrategy: 2,
    PrePlaceOrder: 3,
    PlaceOrder: 4,
    AnalyzeResult: 5,
    SaveResult: 6,
    Done: 7,
};

export const globalReducer = (state = initialState, action) => {
    console.log("state", state, action);
    console.log("action", state, action);
    switch (action.type) {
        case CHANGE.SETTINGS.EXPERT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    experts: [...action.payload.data],
                },
                postRun: {
                    ...state.postRun,
                    expert: action.payload.data[0],
                },
            };
        case CHANGE.RESULT:
            return {
                ...state,
                result: [...action.payload.data],
            };
        case CHANGE.JOURNAL:
            if (Array.isArray(action.payload.data))
                return {
                    ...state,
                    journal: [...action.payload.data],
                };
            return {
                ...state,
                journal: [{ ...action.payload.data }, ...state.journal],
            };
        case CHANGE.BACKTEST:
            return {
                ...state,
                backtest: { ...action.payload.data },
            };
        case CHANGE.SETTINGS.SYMBOL:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    symbol: [...action.payload.data],
                },
                postRun: {
                    ...state.postRun,
                    symbol: action.payload.data[0],
                },
            };
        case CHANGE.POSTRUN.INDEX:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    ...action.payload.data,
                },
            };
        case CHANGE.POSTRUN.EXPERT:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.EXPERT]: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.SYMBOL:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.SYMBOL]: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.TIMEFRAME:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.TIMEFRAME]: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.DATE:
            let date_data = [...state.postRun.date];
            if (action.payload.data.hasOwnProperty("start")) {
                date_data[0] = action.payload.data.start;
            }
            if (action.payload.data.hasOwnProperty("end")) {
                date_data[1] = action.payload.data.end;
            }
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.DATE]: [...date_data],
                },
            };
        case CHANGE.POSTRUN.DELAY:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.DELAY]: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.MODELING:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.MODELING]: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.DEPOSIT:
            let newDeposit = {
                ...state.postRun.deposit,
            };
            if (action.payload.data.hasOwnProperty("type")) {
                newDeposit.type = action.payload.data.type;
            }
            if (action.payload.data.hasOwnProperty("value")) {
                newDeposit.value = action.payload.data.value;
            }
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.DEPOSIT]: {
                        ...newDeposit,
                    },
                },
            };
        case CHANGE.POSTRUN.LEVERAGE:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.LEVERAGE]: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.OPTIMIZATION:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.OPTIMIZATION]: action.payload.data,
                    inputs: state.postRun?.inputs.map((input) => {
                        return {
                            ...input,
                            optimize: false,
                        };
                    }),
                },
            };
        case CHANGE.POSTRUN.INPUT_LIST:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    inputs: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.PROPERTIES_LIST:
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    properties: action.payload.data,
                },
            };
        case CHANGE.POSTRUN.INPUTS:
            let newInputs = [...state.postRun.inputs];
            let newKey, newValue, name;
            if (action.payload.data.hasOwnProperty("key")) {
                newKey = action.payload.data.key;
            }
            if (action.payload.data.hasOwnProperty("value")) {
                newValue = action.payload.data.value;
            }
            if (action.payload.data.hasOwnProperty("name")) {
                name = action.payload.data.name;
            }
            const currentRowIndex = newInputs.findIndex((x) => x.name === name);
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.INPUTS]: [
                        ...newInputs.slice(0, currentRowIndex),
                        {
                            ...newInputs[currentRowIndex],
                            [newKey]: newValue,
                        },
                        ...newInputs.slice(currentRowIndex + 1),
                    ],
                },
            };
        case CHANGE.POSTRUN.PROPERTIES:
            let newProperties = { ...state.postRun.properties };
            let nKey, nSubKey, nValue;
            if (action.payload.data.hasOwnProperty("key")) {
                nKey = action.payload.data.key;
            }
            if (action.payload.data.hasOwnProperty("value")) {
                nValue = action.payload.data.value;
            }
            if (action.payload.data.hasOwnProperty("subKey")) {
                nSubKey = action.payload.data.subKey;
            }
            // console.log('nKey, nSubKey, nValue', nKey, nSubKey, nValue)
            return {
                ...state,
                postRun: {
                    ...state.postRun,
                    [CHANGE.POSTRUN.PROPERTIES]: {
                        ...newProperties,
                        [nKey]: {
                            ...newProperties[nKey],
                            [nSubKey]: {
                                ...newProperties[nKey][nSubKey],
                                value: nValue,
                            },
                        },
                    },
                },
            };
        default:
            return state;
    }
};

import React, { useState } from "react";
import { useGlobal } from "../../hooks/useGlobal";
import YButton from "../button/YButton";
import Tab from "./Tab";
import "./YTab.scss";

const YTab = (props) => {
    const { children } = props;
    // const [activeTab, setActiveTab] = useState(activeTabProps);
    const { postRun, processing, onClickFocus, activeTab, setActiveTab } = useGlobal();

    const onClickTabItem = (tab) => {
        setActiveTab(tab);
        onClickFocus(-1);
    };

    const handleStart = () => {
        postRun();
    };

    return (
        <div className="tabs">
            <ol className="tab-list">
                {children.map((child) => {
                    const { label } = child.props;
                    return (
                        <Tab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={onClickTabItem}
                        />
                    );
                })}
                <div className="process-bar">
                    <div
                        className="complete-bar"
                        style={{
                            width: `${processing === -1 ? 0 : (processing / 7) * 100
                                }%`,
                        }}
                    ></div>
                </div>
                <YButton className="start-btn" onClick={handleStart}>
                    Start
                </YButton>
            </ol>
            <div className="tab-content">
                {children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

export default YTab;
